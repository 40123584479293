@font-face {
  font-family: "regular";
  src: url("./assets/fonts/Jost-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: "bold";
  src: url("./assets/fonts/Jost-Bold.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
.regular{font-family: "regular";}
.bold{font-family: "bold";}
/* default start */
.padding{padding: 90px 0;}
.foot-pad{padding:30px 0  ;}
.logo{width:120px;}
.navbar-expand-lg .navbar-nav .nav-link { 
    padding: 10px 40px !important;
font-family: "bold";
    font-size: 17px;
}
.navfont .nav-link.active {
    color: #F49D1A !important;
    border-bottom: 2px solid #F49D1A;
  }
  .navbar-nav .nav-link.active {
    color: #F49D1A !important;
    border-bottom: 3px solid #F49D1A;
  }
/* default end */
/* app bar start */
.top-bg{
  background-image: url("./assets/images/topbg.webp");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  background-position: center;
}
.top-bg::before{
  background: #000000ab none repeat scroll 0 0;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
#home {
  display: table;
  width: 100%;
  height: 100%;
  min-height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  background-position: 50% 0px;
}
.home-center {
  height: 100vh;
  vertical-align: middle;
  display: table-cell;
  position: relative;
}
  .home-center img{
    width: 200px;
    margin: 0 auto;
  }
  .down svg{
    color: #ffffff;
    font-size: 55px;
  
  }
.quote{font-size: 55px; 
    color: #fff;
    font-family: 'regular';}
.mark-bg{ background-color: #F49D1A;
  color: black;
  font-family: "regular";}
  .estimate{
    background: #F49D1A !important;
    padding: 10px !important;
    font-family: "bold" !important;
    color: black !important;
  }
/* app bar end */
/* footer start */
.list-unstyled li{padding: 12px 0;}
.list-unstyled li a {
  text-decoration: none !important;
  color: #F49D1A !important;
  font-family: 'regular';
}
.rules{line-height: 35px;
font-family: 'regular';}
.footer-bg{ background-color: #ededed;
font-family: "regular";}
.ft-logo{
  width:100px;
}
.ft-icon{
  background: #F49D1A;
  padding: 12px;
  border-radius: 50%;
  color: #fff;
  font-size: 32px;
  line-height: 10px;
   }
.ft-details{
  margin: 0 20px;
   
}
/* footer end */