.title{padding: 12px 30px; 
    display: inline-block; 
    color: #fff; 
    border-radius: 10px; 
    font-size: 20px; 
    font-family: "bold";}
.bg-green{background-color: #038403;}
.bg-red{background-color: red;}
.safe-list{display: flex; align-items: center;}
.box-green{border: 3px solid #00882d;
    border-radius: 8px;
    padding: 12px;
    color: #61b628;
    margin: 10px 0;font-family: "regular";
}
.box-red{border: 3px solid #f1240d;
    border-radius: 8px;
    padding: 12px;
    color: #f23e22;
    margin: 10px 0;font-family: "regular";}
.box{box-shadow: rgba(0,0,0,.05)0 6px 24px 0,rgba(0,0,0,.08)0 0 0 1px;
        padding: 30px; 
        border-radius: 8px;
    text-align: center;font-family: "regular";}
.box-icon{ 
    background-color: #ff5862; 
    padding: 12px; border-radius: 50%;
    display: inline-block; margin: 12px auto;
}